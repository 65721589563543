import { styled, Text, spacing, mq } from '@thg-commerce/enterprise-theme'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import { dangerous_mqMax } from '@thg-commerce/gravity-theme'
import { SafeHtml } from '@thg-commerce/gravity-elements'

export const IconContainer = styled(GridItem)`
  width: 100%;
  padding: ${spacing(2)} ${spacing(5)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  grid-row: auto;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(2)} ${spacing(3)};
    grid-column: span 1;
  }
`

export const TitleGridItem = styled(GridItem)`
  ${(props) => dangerous_mqMax(props.theme.breakpointUtils.map, 'sm')} {
    grid-area: 1 / 1 / span 1 / span 6;
    margin-bottom: ${spacing(2)};
  }
`

export const StyledIcon = styled.a`
  width: 24px;
  height: 24px;
  display: block;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    border: 1px solid transparent;
    border-radius: 100vh;
    position: absolute;
    inset: 0 0 0 0;
    margin: auto;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    transform: scale(0.1);
    z-index: -1;
  }

  &:hover {
    opacity: 0.7;
    &:after {
      border-color: ${(props) => props.theme.colors.palette.greys.dark};
      transform: scale(1.8);
      z-index: 1;
      opacity: 0.7;
    }
  }
`

export const Title = styled(SafeHtml)`
  ${Text('large1', 'alternate')};
  text-align: center;
  padding-top: 0;
`

export const StyledGrid = styled(Grid)<{ inlineLayout?: boolean }>`
  align-items: center;
  width: 100%;
  gap: ${spacing(3)} 0;
  ${(props) => dangerous_mqMax(props.theme.breakpointUtils.map, 'sm')} {
    grid-template-columns: repeat(6, [col] 1fr);
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(4)};
    gap: ${spacing(5)};
  }
`
