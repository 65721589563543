import * as React from 'react'

import {
  Title,
  StyledGrid,
  IconContainer,
  TitleGridItem,
  StyledIcon,
} from './styles'
import { getSocialIcons } from './getSocialIcons'

export interface SocialIconCollectionProps {
  inlineLayout?: boolean
  title?: string
  banners: SocialIconProps[]
  className?: string
}

export interface SocialIconProps {
  icon: string
  url?: string
}

export const SocialIconCollection = ({
  inlineLayout,
  title,
  banners,
  className,
}: SocialIconCollectionProps) => {
  const iconRows = (icons) => (inlineLayout ? icons.length + 4 : icons.length)
  const titleRows = (title) => (inlineLayout ? 3 : title.length)
  const evenColSpan: boolean = banners.length % 2 < 1 && banners.length <= 4
  const setColSpan: number = evenColSpan ? 3 : 2

  const socialIcons = banners.map((item, index) => {
    return (
      item.icon && (
        <IconContainer
          key={`social-icon-${index}`}
          data-testid="social-icon-container"
          rowStart={inlineLayout ? 1 : 2}
          colSpan={[setColSpan, 1, 1, 1]}
          onKeyDown={(e) =>
            e.key === 'Enter' && window.open(item.url, '_blank')
          }
          tabIndex={0}
        >
          <StyledIcon
            title={item.icon}
            href={item.url || ''}
            data-testid="social-icon-url"
            target="_blank"
            tabIndex={-1}
          >
            {getSocialIcons(item.icon)}
          </StyledIcon>
        </IconContainer>
      )
    )
  })

  return (
    <div className={className}>
      <StyledGrid columns={iconRows(socialIcons)}>
        {title && (
          <TitleGridItem
            colSpan={titleRows(socialIcons)}
            colStart={1}
            rowStart={1}
          >
            <Title __dangerouslyAllowedTags={['font']} content={title} />
          </TitleGridItem>
        )}
        {socialIcons}
      </StyledGrid>
    </div>
  )
}
