import * as React from 'react'
import dynamic from 'next/dynamic'

const FacebookIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/FacebookOutline'),
)
const TwitterIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/TwitterOutline'),
)
const PinterestIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/ContentPinterest'),
)
const WhatsappIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/WhatsappIcon'),
)

const InstagramIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackInstagram'
    ),
)

const WeiboIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackWeibo'
    ),
)

const VkIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackVk'
    ),
)

const YoutubeIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackYouTube'
    ),
)

const KakaoIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackKakao'
    ),
)

const GooglePlusIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackGooglePlus'
    ),
)

const TikTokIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackTikTok'
    ),
)

const LineIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackLine'
    ),
)

const TwentyOneButtonsIcon = dynamic(
  () =>
    import(
      '@thg-commerce/gravity-icons/src/components/SocialIcons/Black/BlackTwentyOneButtons'
    ),
)

export const getSocialIcons = (socialNetwork: string) => {
  switch (socialNetwork) {
    case 'Facebook':
      return <FacebookIcon />
    case 'Twitter':
      return <TwitterIcon />
    case 'Pinterest':
      return <PinterestIcon />
    case 'Whatsapp':
      return <WhatsappIcon />
    case 'Instagram':
      return <InstagramIcon />
    case 'Weibo':
      return <WeiboIcon />
    case 'VK':
      return <VkIcon />
    case 'Youtube':
      return <YoutubeIcon />
    case 'KakaoTalk':
      return <KakaoIcon />
    case 'GooglePlus':
      return <GooglePlusIcon />
    case 'Tiktok':
      return <TikTokIcon />
    case 'Line':
      return <LineIcon />
    case '21Buttons':
      return <TwentyOneButtonsIcon />
    default:
      return null
  }
}
