import * as React from 'react'
import { spacing, styled } from '@thg-commerce/enterprise-theme'
import {
  GlobalSocialIcon,
  GlobalSocialIconCollection as GlobalSocialIconCollectionv1,
  GlobalSocialIconv2,
  GlobalSocialIconCollectionv2,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  SocialIconCollection,
  SocialIconCollectionProps,
} from '@thg-commerce/enterprise-widget-social-icon-collection'

import { BaseWidgetProps } from '../types'

export type SocialIconCollectionRendererProps = BaseWidgetProps &
  Omit<
    GlobalSocialIconCollectionv1 | GlobalSocialIconCollectionv2,
    'query' | 'id' | 'banners'
  > & {
    banners?: Omit<GlobalSocialIcon | GlobalSocialIconv2, 'query'>[]
  }

const SocialIconCollectionContainer = styled(SocialIconCollection)`
  margin: ${spacing(2)} auto;
  max-width: ${(props) => props.theme.site.siteWidth};
`

export const SocialIconCollectionRenderer = (
  props: SocialIconCollectionRendererProps,
) => {
  if (!props.banners?.length) {
    return null
  }
  const socialIconCollectionProps: SocialIconCollectionProps = {
    banners: props.banners.map((item: any) => ({
      icon: item.icon,
      url: item.url || '',
    })),
    title: props.title || '',
    inlineLayout: false,
  }
  return <SocialIconCollectionContainer {...socialIconCollectionProps} />
}
